import React from 'react';
import './style.css';

export interface TutorialCardProps {
    name: string;
    logo?: string;
    numberOfTutorials?: number;
    comingSoon?: boolean;
    click?: () => void;
}

export default function TutorialCard(props: TutorialCardProps) {
    return (
        <div className='cardWrapper'>
            { props.comingSoon && <div className='overlay'>
            </div> }
            <div className='cardContent' onClick={props.click}>
                <img className='img' src={props.logo}/>
                <span className='cardTitle'>{props.name}</span>
                { props.numberOfTutorials && 
                    <div>
                        <span className='count'>{props.numberOfTutorials} tutorials</span>
                    </div>
                }
            </div>
        </div>
    );
}