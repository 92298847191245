import React from "react";
import { FiClipboard } from "react-icons/fi";
import Link from "../../components/link/Link";
import PageNavigator from "../../components/pageNavigator/PageNavigator";

export default function EnvironmentSetup() {

    const copy = () => {
        navigator.clipboard.writeText('Copy this text to clipboard')
    }

    return (
        <div className="tutorialViewWrapper">
            <h2 className="t-title"> Setting up the environment to start coding </h2>

            <p className="text-base leading-6 text-gray-700" style={{ fontWeight: 300 }}>
                Before you begin, you should download the Java Development Kit (JDK). As of now the latest version is JDK 19. So we reccomend to
                download the latest version from the <Link linkAddress="https://jdk.java.net/19/" linkLabel="official website" target="blank" />.
            </p>

            <h3 style={{margin: '1rem 0', fontWeight: 500}}>For linux OS</h3>
            <p className="text-base leading-6 text-gray-700" style={{ fontWeight: 300 }}>
                <ol className="inst-steps">
                    <li>Download the latest JDK binary
                        &nbsp; (<Link
                            linkAddress="https://download.java.net/java/GA/jdk19.0.2/fdb695a9d9064ad6b064dc6df578380c/7/GPL/openjdk-19.0.2_linux-aarch64_bin.tar.gz"
                            linkLabel="JDK 19" /> )
                    </li>
                    <li>
                        Extract the file to <strong>/opt</strong> directory
                    </li>
                    <li>Copy file path of the <strong>/bin</strong> directory
                        <div className="codeBg">
                            /opt/jdk-19.0.1/bin
                        </div>
                    </li>
                    <li>Set <strong>JAVA_HOME</strong> path variable
                        <div className="codeBg mv-10" >
                            <div className="copy-able-txt">$ vim /etc/profile <FiClipboard className="copy" onClick={copy}/></div>
                        </div>
                        <div className="codeBg mv-10">
                            <div>export JAVA_HOME="/opt/jdk-19.0.1/bin"</div>
                            <div>export PATH=$JAVA_HOME/bin:$PATH</div>
                        </div>
                        <div className="codeBg mv-10">
                            <div className="copy-able-txt">$ source /etc/profile <FiClipboard className="copy" onClick={copy}/></div>
                        </div>
                    </li>
                    <li>Verify the setup - run the following command to verify the installation
                        <div className="codeBg mv-10">
                            <div className="copy-able-txt">$ javac <FiClipboard className="copy" onClick={copy}/></div>
                        </div>
                        You will see an output like below if the evironment set up properly
                        <div className="codeBg mv-10">
                            <img src="/javac.png" alt="tutor | java output"/>
                        </div>
                    </li>
                </ol>
            </p>

            <div className="mv-10" style={{marginTop: '3rem'}}>
                So , All set for the next step .Congratulations !. 
            </div>

            {/* <h3>For windows</h3> */}

            <div style={{ marginTop: '40px' }}>
                <PageNavigator
                    nextTitle="First java application"
                    previousTitle="What is java ?"
                    previousSubTitle="Introduction of JAVA language, history and features"
                    nextSubTitle="Write your first java application to understand the process"
                    nextLink="/tutorials/java/4"
                    previouslink="/tutorials/java/2" />
            </div>
        </div>
    )
}