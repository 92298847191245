import React from "react";
import { useNavigate } from "react-router-dom";
import TutorialCard from "../../components/tutorialCard/TutorialCard";
import './styles.css';

export interface MenuItem {
    name: string;
    icon?: string;
    code?: string;
    count?: number;
    comingSoon?: boolean;
}

export interface TutorialsWrapperProps {

}

export default function TutorialsWrapper(props: TutorialsWrapperProps) {

    const navigate = useNavigate();

    const languages: MenuItem[] = [
        { name: 'Java', icon: 'java.png', count: 3, code: 'java'},
        { name: 'Java Script', comingSoon: true},
        { name: 'HTML', icon: 'html.png', comingSoon: true },
        { name: 'CSS' , comingSoon: true},
        { name: 'Python', icon: 'Python.png', comingSoon: true },
        { name: 'Typescript', comingSoon: true },
        { name: 'Node JS', icon: 'node.png', comingSoon: true },
        { name: 'Hibernate', icon: 'hibernate.png', comingSoon: true },
        { name: 'Spring', comingSoon: true },
        { name: 'MySQL', icon: 'Mysql.png', comingSoon: true },
        { name: 'MongoDB' ,icon: 'mongodb.png', comingSoon: true },
        { name: 'ArangoDB', icon: 'arango.png', comingSoon: true },
        { name: 'Kubernetes(k8s)', icon: 'k8s.svg', comingSoon: true },
        { name: 'Docker', icon: 'docker.png', comingSoon: true },
    ];

    const handleClick = (code: string) => {
        navigate(code + '/1');
    }

    return (
        <div className="contentWrapper mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h2 className="title">Tutorials</h2>
            <div className="wrapperContent">
                {languages.map((category: any) => {
                    return (
                        <TutorialCard 
                            comingSoon={category.comingSoon}
                            name={category.name} 
                            logo={category.icon} 
                            numberOfTutorials={category.count} 
                            click={() => handleClick(category.code)}/>
                    )
                })}
            </div>
        </div>
    );
}