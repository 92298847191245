import bgImg from '../../assets/images/bg-img.jpg';


export default function HeaderSection() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32" style={{height: '75vh'}}>
      <img style={{objectPosition: 'bottom'}}
        src={bgImg}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
      />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Learn , Share and Contribute to grow</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
            fugiat veniam occaecat fugiat aliqua.
          </p>
        </div>
      </div>
    </div>
  )
}
