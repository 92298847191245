import React from "react";
import './Footer.css';

const Footer = () => {
    return(
        <footer>
            Developed by | Cloudinate Information Technology | 2023 | <a href="/privacy-policy.html" style={{color: 'rgb(12 168 205)'}}>Privacy policy</a>
        </footer>
    );
};
export default Footer;