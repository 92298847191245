import React from "react";
import './style.css';

export interface LinkProps {
    linkAddress?: string;
    linkLabel?: string;
    target?: 'blank' | 'none';
}

export default function Link(props: LinkProps) {
    return (
        <a className="link" href={props.linkAddress} target={props.target}>
            {props.linkLabel}
        </a>
    )
}