import React from "react";
import './style.css';
import { FiArrowRightCircle, FiArrowLeftCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export interface PageNavigatorProps {
    nextTitle?: string;
    nextSubTitle?: string;
    nextLink?: string;
    previousTitle?: string;
    previousSubTitle?: string;
    previouslink?: string;
}

export default function PageNavigator(props: PageNavigatorProps) {

    const navigate = useNavigate();

    const handleNavigate = (link: string) => {
        navigate(link);
    }

    return (
        <div className="navWrapper">
            <div style={{width: "50%"}}>
                { props.previousTitle && 
                    <div className="nav"  
                        onClick={() => handleNavigate(props.previouslink!)}>
                        <div style={{ padding: "0 16px 0 0;"}}>
                            <FiArrowLeftCircle size={35} color="#aeaeae" className="v-mid"/>
                        </div>
                        <div style={{width: "90%", textAlign: 'right'}}>
                            <div style={{width: "100%"}}>{props.previousTitle}</div>
                            <div className="summary">
                                {props.previousSubTitle}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div style={{width: "50%"}}>
                { props.nextTitle && 
                    <div className="nav"  
                        onClick={() => handleNavigate(props.nextLink!)}>
                        <div style={{width: "90%"}}>
                            <div style={{width: "100%"}}>{props.nextTitle}</div>
                            <div className="summary">
                                {props.nextSubTitle}
                            </div>
                        </div>
                        <div style={{ padding: "16px 0 0 0;"}}>
                            <FiArrowRightCircle size={35} color="#aeaeae" className="v-mid"/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}