import React from "react";
import { Outlet } from "react-router-dom";

export interface TutorialViewProps {

}

export default function TutorialView(props: TutorialViewProps) {
    return (
        <Outlet/>
    );
}