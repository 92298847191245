import React from "react";
import Link from "../../components/link/Link";
import PageNavigator from "../../components/pageNavigator/PageNavigator";

export default function IntroductionToJava() {
    return (
        <div className="tutorialViewWrapper">
            <h2 className="t-title"> What is Java ?</h2>

            <div>
                <p className="f-w-300 text-base leading-6 text-gray-700">
                    Java is a programming language which is using millions of developers world wide. It is high performance , 
                    Object oriented and high level programming language . As well as a platform. We can point out following 
                    characteristics of Java as a programming language.
                    <ul className="un-ordered-li">
                        <li> Object oriented </li>
                        <li> Portable </li>
                        <li> Multithreaded </li>
                        <li> Distributed </li>
                        <li> High performance </li>
                        <li> Secure </li>
                    </ul>
                </p>
            </div>

            <h2 className="t-title m-v-16"> History of JAVA </h2>
            <div>
                <p className="f-w-300 text-base leading-6 text-gray-700">
                    A team lead by <Link linkAddress="https://en.wikipedia.org/wiki/James_Gosling" linkLabel="James Gosling" target="blank"/> 
                    &nbsp;(<strong>a Canadian computer scientist</strong>) has been initiated a project to design a new programming language in 1991.It was called 
                    &nbsp;<strong>Oak</strong> (Because of an Oak tree stood at the front of Gosling's office). The purpose fo this project was to use for 
                    interactive television. After some times , the project renamed to &nbsp;<strong>Green</strong> and finally it renamed to &nbsp;<strong>Java</strong>.
                </p>

                <p className="f-w-300 text-base leading-6 text-gray-700">
                    <Link linkAddress="https://en.wikipedia.org/wiki/Sun_Microsystems" linkLabel="Sun Microsystems"/> released the very first public version of Java as version 1.0 in 1996. When they were releasing the first 
                    Java version , they used a slogan called &nbsp; <i>"Write Once , Run Anywhere (WORA)"</i> &nbsp;to illustrate the &nbsp; <strong>cross-platform </strong>  
                    benifit of the Java language. Even the language originally developed and released by Sun Microsystems , currently the owner is 
                    &nbsp;<Link linkAddress="https://www.oracle.com/" linkLabel="Oracle Corporation"/> because Sun Microsystems has been aquired by the Orcale Corporation
                    on 2010.
                </p>
            </div>

            <h2 className="t-title m-v-16"> Java versions </h2>
            <div>
                <p className="f-w-300 text-base leading-6 text-gray-700">
                    Sun Microsystems released &nbsp;<strong>JDK 1.0</strong> on 1996 as the first Java version. There are lots of major versions released so far 
                    with improving the language. Current latest version (As of 2023) is &nbsp;<strong>JDK 19 </strong> and &nbsp;<strong>Java 17</strong> LTS is 
                    the latest long-term support release for the Java SE platform. Currently , Java is free for non-commercial uses (Anyone can download the JDK 
                    free of charge and can use for non-commercial purposes). From JDK 11 onward , a commercial license require to use java for commercial purposes.
                </p>
            </div>

            <div style={{marginTop: '40px'}}>
                <PageNavigator 
                    nextTitle="Setting up the environment" 
                    previousTitle="Introduction"
                    nextSubTitle="Introduction of JAVA language, history and features" 
                    previousSubTitle="Introduction of JAVA language, history and features" 
                    nextLink="/tutorials/java/3" 
                    previouslink="/tutorials/java/1"/>
            </div>
        </div>
    )
}