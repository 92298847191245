import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import './styles.css';

export interface Topic {
    title: string;
}

export interface TutorialProps {
    children?: Element;
}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Tutorials(props: TutorialProps) {

    const params = useQuery();
    const [title, setTitle] = useState<String>('Tutorials');
    const navigate = useNavigate();

    const topics: Topic[] = [
        { title: "Introduction" },
        { title: "What is Java " },
        { title: "Getting start with latest version " },
        { title: "First java programe" },
        { title: "basic step" },
        { title: "Variables" },
        { title: "Data types" },
        { title: "Identifiers" },
        { title: "Operators" },
        { title: "Arrays" },
        { title: "Switch & case" },
        { title: "If else" },
        { title: "Strings" },
        { title: "Class" },
        { title: "Method" },
        { title: "OOP concepts" },
        { title: "Exception handling" },
    ];

    useEffect(() => {
        setTitle(params.get('sel')!);
    }, [params]);

    const item = (category: Topic): any => {
        return <li className="sItem" onClick={() => {
            navigate('/tutorials?sel=' + category.title);
        }}>
            <span>{category.title}</span>
        </li>
    };

    return (
        <div className="contentWrapper mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="titltSection">Java Tutorials for begginners</div>
            <div className="contentOuter">
                <div className="sidePanel">
                    <div>
                        <ul>
                            {topics.map((category: any) => {
                                return (
                                    item(category)
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="main-view">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}