import React from "react";

export interface BlogCardProps {
    data?: any;
}

export default function BlogCcard(props: BlogCardProps) {
    const {data} = props;
    return (
        <article key={data.id} className="flex max-w-xl flex-col items-start">
            <div className="flex items-center gap-x-4 text-xs">
                <time dateTime={data.datetime} className="text-gray-500">
                    {data.date}
                </time>
                <a
                    href={data.category.href}
                    className="relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100"
                >
                    {data.category.title}
                </a>
            </div>
            <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={data.href}>
                        <span className="absolute inset-0" />
                        {data.title}
                    </a>
                </h3>
                <p className="mt-5 text-sm leading-6 text-gray-600 line-clamp-3">{data.description}</p>
            </div>
            <div className="relative mt-8 flex items-center gap-x-4">
                <img src={data.author.imageUrl} alt="img" className="h-10 w-10 rounded-full bg-gray-50" />
                <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                        <a href={data.author.href}>
                            <span className="absolute inset-0" />
                            {data.author.name}
                        </a>
                    </p>
                    <p className="text-gray-600">{data.author.role}</p>
                </div>
            </div>
        </article>
    )
}