import { Popover } from '@headlessui/react'
import React from 'react'
import logo from '../../assets/images/logo.png'
import { useNavigate } from 'react-router-dom'

const navigation = {
  categories: [
    {
      id: 'Home',
      path: '/',
      name: 'Home',
      featured: [],
      sections: [],
    },
    {
      id: 'Tutorials',
      name: 'Tutorials',
      path: '/tutorials',
    }, {
      id: 'aboutUs',
      name: 'About us',
      path: '/tutorials',
    },{
      id: 'aboutUs',
      name: 'Contact us',
      path: '/tutorials',
    },
    {
      id: 'Blog',
      path: '/blogs',
      name: 'Blog',
    },
  ],
  pages: [
    // { name: 'Company', href: '#' },
    // { name: 'Stores', href: '#' },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {

  let navigate = useNavigate()

  return (
    <div className="bg-white">
      <header className="relative bg-white" style={{ zIndex: 1500, position: 'fixed', width: '100%' }}>
        <nav aria-label="Top" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="border-b border-gray-200">
            <div className="flex h-16 items-center">

              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <a href="#">
                  <span className="sr-only"></span>
                  <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="masterTech"
                  />
                </a>
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                <div className="flex h-full space-x-8">
                  {navigation.categories.map((category) => (
                    <Popover key={category.name} className="flex">
                      {({ open }) => (
                        <>
                          <div className="relative flex">
                            <Popover.Button
                              onClick={() => navigate(category.path)}
                              className={classNames(
                                open
                                  ? 'border-indigo-600 text-indigo-600'
                                  : 'border-transparent text-gray-700 hover:text-gray-800',
                                'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out'
                              )}
                            >
                              {category.name}
                            </Popover.Button>
                          </div>
                        </>
                      )}
                    </Popover>
                  ))}

                  {navigation.pages.map((page) => (
                    <a
                      key={page.name}
                      href={page.href}
                      className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                    >
                      {page.name}
                    </a>
                  ))}
                </div>
              </Popover.Group>

              <div className="ml-auto flex items-center">
                <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  <a href="#" className="text-sm font-medium text-gray-700 hover:text-gray-800">
                    Sign in
                  </a>
                  <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                  <a href="#" className="text-sm font-medium text-gray-700 hover:text-gray-800">
                    Create account
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}
