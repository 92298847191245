import React from "react";
import PageNavigator from "../../components/pageNavigator/PageNavigator";
import './style.css'

export default function Introduction() {
    return (
        <div className="tutorialViewWrapper">
            <h2 className="t-title">Introduction of the tutorial session</h2>
            <div>
                <img className="intro-img" src="/java.png" alt="tutor.cloudinateit.com"/>
            </div>
            <p className="f-p text-base leading-6 text-gray-700" style={{fontWeight: 300}}>
                Java is a one of very famous high level language that is using millions of developers worldwide to develop 
                Standalone applications, mobile applications and web applications.
                The purpose of introducing this set of Java tutorilas is to provide a good understand about the language (history , 
                features, stregths and so on) , 
                expanding the area if someone who already falimilar the language , deep dive to the language with more real world 
                examples and latest changes. Mainly this set of tutorials will be covered your basic knowledge , and further more
                for fit to the career growth. 
            </p>
            
            <p className=" text-base leading-6 text-gray-700" style={{fontWeight: 300, marginTop: '1rem', marginBottom: '1rem'}}>
                During this session , we will be provided a codeing playground which enabled the runtime environment to 
                tryout the examples same time and that will be helpful to understand the mechanism at the same time. 
            </p>

            <h4> So lets start and Happy coding !</h4>

            <div style={{marginTop: '16px'}}>
                <PageNavigator nextTitle="What is java ?" nextSubTitle="Introduction of JAVA language, history and features" nextLink="/tutorials/java/2"/>
            </div>
        </div>
    )
}