import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import NotFound from "./pages/404/404";
import Blog from "./pages/Blog/Blog";
import ContainerLayout from "./pages/Layout/CcontainerLayout/ContainerLayout";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import Tutorials from "./pages/Tutorials/Tutorials";
import TutorialsWrapper from "./pages/Tutorials/TutorialsWrapper";
import TutorialView from "./pages/Tutorials/TutorialView";
import EnvironmentSetup from "./tutorials/java/env-setup";
import Introduction from "./tutorials/java/introduction";
import IntroductionToJava from "./tutorials/java/introductionToJava";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <NotFound />,
    }, {
        element: <ContainerLayout />,
        children: [
            {
                path: "/blogs",
                element: <Blog />,
            }, 
            {
                path: "/tutorials",
                element: <TutorialsWrapper />,
            },
            {
                path: "/tutorials",
                element: <Tutorials />,
                children: [
                    {
                        path: "java",
                        element: <TutorialView />,
                        children: [
                            { path: "1", element: <Introduction /> },
                            { path: "2", element: <IntroductionToJava /> },
                            { path: "3", element: <EnvironmentSetup /> },
                            { path: "4", element: <IntroductionToJava /> },
                            { path: "5", element: <IntroductionToJava /> },
                            { path: "6", element: <IntroductionToJava /> },
                            { path: "7", element: <IntroductionToJava /> },
                        ]
                    }
                ]
            },
        ]
    },
]);


export default router;